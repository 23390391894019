@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100vh;
}

.bg-legend-green {
  background-color: #cfda28;
}

.bg-legend-purple {
  background-color: #914dec;
}

.fill-legend-purple {
  fill: #914dec;
}

.text-legend-purple {
  color: #914dec;
}

.border-legend-purple {
  border-color: #914dec;
}

.cropper-container .cropper-view-box {
  outline-width: 3px;
}

.cropper-container .cropper-point {
  width: 10px;
  height: 10px;
}

.night-mode {
  background-color: black !important;
  transition: background-color 400ms linear;
}

.night-mode .night-mode-element {
  background-color: black !important;
  transition: background-color 400ms linear;
}

.night-mode .night-mode-hidden {
  display: none !important;
}

.night-mode .night-mode-text {
  color: black !important;
}
